
import { defineComponent, onMounted, onUpdated, ref, useAttrs } from "vue";
import { MBDatatable } from "magicbean-saas-common";
import { useRoute, useRouter } from "vue-router";
import { ApiAuth } from "@/core/api";
import { useI18n } from "vue-i18n";
import { SortOption, FilterOption } from "@/core/directive/interface/common";
import AddMerchant from "./AddMerchant.vue";
import mixin from "@/mixins";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { formatUtcDate } from "@/core/directive/function/common";
import { ScrollComponent } from "@/assets/ts/components";
import { setModuleBCN } from "@/core/directive/function/common";

export default defineComponent({
  name: "user-management-merchant",
  components: {
    MBDatatable,
    AddMerchant,
  },
  setup() {
    const { t } = useI18n();
    const attrs = useAttrs(); // 接收父路由参数

    const route = useRoute();
    const router = useRouter();
    const { showServerErrorMsg } = mixin();

    const loading = ref(true);
    const tableData = ref<Array<any>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const search = ref<string>("");
    const isDisabledAdd = ref<boolean>(false);

    const filterOptions = ref<Array<FilterOption>>([]);

    const sortOptions = ref<Array<SortOption>>([
      { field: "id", direction: "desc" },
    ]);
    const tableHeader = ref([
      {
        name: t("user.companyName"),
        key: "company_name",
        sortable: false,
      },
      {
        name: t("user.country"),
        key: "country",
        sortable: false,
      },
      {
        name: t("user.city"),
        key: "city",
        sortable: false,
      },
      {
        name: t("user.lastValidationDate"),
        key: "last_validation_date",
        sortable: false,
      },
      {
        name: t("user.lifecycleStatus"),
        key: "lifecycle_status",
        sortable: false,
      },
      {
        name: t("user.vatRegistrationNumber"),
        key: "vat_registration_number",
        sortable: false,
      },
      {
        name: t("common.action"),
        key: "actions",
        sortable: false,
      },
    ]);

    const options = ref({
      FulfillmentOrderStatus: "",
      saleOrderType: "",
      saleOrderNumber: "",
    });

    onMounted(() => {
      setModuleBCN(t, route, router);
      init();
    });

    onUpdated(() => {
      ScrollComponent.reinitialization();
    });

    const removeItem = (item) => {
      Swal.fire({
        text: t("common.deleteTip"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.okConfirm"),
        cancelButtonText: t("common.nopeCancelIt"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          // item.deleteDisabled = true;
          loading.value = true;
          const { data } = await ApiAuth.deleteUserMerchant({
            id: route.params.id,
            merchant_ids: [item.object_id],
          });
          // item.deleteDisabled = false;
          loading.value = false;
          if (data.code == 0) {
            updateList();
          } else {
            showServerErrorMsg(data);
          }
        }
      });
    };

    const getUserMerchantList = async () => {
      loading.value = true;
      const { data } = await ApiAuth.getUserMerchantList({
        page: currentPage.value,
        page_size: pageSize.value,
        search_value: search.value,
        filter_group: filterOptions.value,
        sort_orders: sortOptions.value,
        id: route.params.id,
      });
      loading.value = false;
      if (data.code == 0) {
        tableData.value.splice(0, tableData.value.length, ...data.data.items);
        total.value = data.data.total;
      }
    };

    const updateList = () => {
      getUserMerchantList();
    };

    const init = () => {
      updateList();
    };

    const onCurrentPageChange = (page: number) => {
      currentPage.value = page;
      updateList();
    };

    const onRowsPerPageChange = (pagesize: number) => {
      pageSize.value = pagesize;
      updateList();
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      updateList();
    };

    return {
      t,
      formatUtcDate,
      route,
      loading,
      isDisabledAdd,
      tableHeader,
      options,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      removeItem,
      onCurrentPageChange,
      onRowsPerPageChange,
      onColumnSort,
      updateList,
    };
  },
});
